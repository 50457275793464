import Description from "../ui/Description";
import Start from "../ui/Start";
import classes from "./Mainview1.module.css";
import bcast from "../layout/desktop/Broadcast.module.css";
import Div100vh from "react-div-100vh";
import React from "react";

const Mainview1 = (props) => {
  return (
    <div className={`${bcast.defaultGrid} ${classes.content1}`}>
      <Start />
      <Description />
    </div>
  );
};

export default Mainview1;
