import "./Layout.module.css";
import React, { Fragment } from "react";
// import useMeasure from "react-use-measure";
// import classes from "./desktop/Content.module.css";
import Sidebar from "./desktop/Sidebar";

import Navbar from "./desktop/Navbar";
import Lines from "./desktop/Lines";
import Content from "./desktop/Content";
import Animation from "./mobile/main/Animation";

// import Background from "./desktop/Background";

// const Div = (props) => {
//   const [ref, { width }] = useMeasure();
//   console.log("Current bounds of element", width);
//   return <div ref={ref} id={props.id} className={props.className}></div>;
// };

const DesktopLayout = (props) => {
  return (
    <Fragment>
      <Sidebar />
      <Navbar />
      <Lines />
      <Content>{props.children}</Content>
    </Fragment>
  );
};

const MobileLayout = (props) => {
  return <Animation/>
};

const Layout = (props) => {
  // Declare a new state variable with the "useState" Hook
  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 767;

  // React.useEffect(() => {
  //   /* Inside of a "useEffect" hook add an event listener that updates
  //      the "width" state variable when the window size changes */
  //   window.addEventListener("resize", () => setWidth(window.innerWidth));
  // }, []);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent)
  return isMobile ? (
    <MobileLayout>{props.children}</MobileLayout>
  ) : (
    <DesktopLayout>{props.children}</DesktopLayout>
  );
};

export default Layout;

