import classes from "./Description.module.css";
import { Slide, Fade } from "react-awesome-reveal";

const Description = (props) => {
  return (
    <div className={classes.description}>
      <Fade delay={1200} style={{ display: "inline-block" }} triggerOnce>
        <Slide delay={1200} fraction={1} style={{ display: "inline-block" }} triggerOnce>
          <hr className={classes.tagline} />
        </Slide>
      </Fade>
      <Fade delay={1700} style={{ display: "inline-block" }} triggerOnce>
        <h5 className={classes.tagname}>yuri fontoura</h5>
      </Fade>

      <h1 className={classes.job}>
        <Fade delay={2100} triggerOnce>
          <Slide direction="right" delay={1700} triggerOnce>
            <span  className={classes.jobline1}>quant</span>
          </Slide>
        </Fade>

        <Fade delay={2600} triggerOnce>
        <span className={classes.jobline2}>
          <span className={classes.jobcolored}>dev</span>
          eloper
        </span>
        </Fade>
      </h1>
    </div>
  );
};

export default Description;
