import React from "react";
import classes from "./Layer.module.css";
import bcast from "../layout/desktop/Broadcast.module.css";

const TopLayer = (props) => {
  return (
    <div className={`${bcast.defaultGrid} ${classes.top}`}>
      {props.children}
    </div>
  );
};

export default TopLayer;
