import "./Sidebar.css";

import { use100vh } from "react-div-100vh";
// import { Link } from "react-router-dom";
import Logo from "../../ui/Logo";

import {
  ImGithub as GH,
  ImLinkedin2 as LI,
  ImWhatsapp as WP,
  ImMail as ML,
} from "react-icons/im";

import { Fade, Slide } from "react-awesome-reveal";

import { scrollToSection } from "../../../functions/functions";

const Sidebar = (props) => {
  const height = use100vh();
  return (
    <Slide duration={500} className={"sidebar"} triggerOnce>
      <aside style={{ height: height }} className={"sidebar"} triggerOnce>
        <Fade delay={300} triggerOnce>
          <span
            className={"sidebarLogo"}
            onClick={() => scrollToSection("home")}
          >
            <Logo.Dash />
            <Logo.Retangle />
            <Logo.Letters />
            {/* <img className={"logoImg"} src={logo} alt="Logo" /> */}
          </span>
        </Fade>

        <Fade cascade delay={500} duration={400} triggerOnce>
          <ul className={"paginationContainer"}>
            <li
              className={`${"pagination"} ${"home"} ${"active"}`}
              onClick={() => scrollToSection("home")}
            ></li>

            <li
              className={`${"pagination"} ${"services"}`}
              onClick={() => scrollToSection("services")}
            ></li>

            <li
              className={`${"pagination"} ${"projects"}`}
              onClick={() => scrollToSection("projects")}
            ></li>

            <li
              className={`${"pagination"} ${"about"}`}
              onClick={() => scrollToSection("about")}
            ></li>

            <li
              className={`${"pagination"} ${"contact"}`}
              onClick={() => scrollToSection("contact")}
            ></li>

            <li
              className={`${"pagination"} ${"ending"}`}
              onClick={() => scrollToSection("ending")}
            ></li>
          </ul>
        </Fade>

        <Fade
          cascade
          delay={1200}
          duration={300}
          triggerOnce
          className={"socialContainer"}
        >
          <a
            className={"social"}
            href="https://www.linkedin.com/in/yurifontoura/"
          >
            <LI size={"1.5vw"} />
          </a>
          <a className={"social"} href="https://wa.me/5521994818884">
            <WP size={"1.5vw"} />
          </a>
          <a className={"social"} href="https://github.com/algo-tradings">
            <GH size={"1.5vw"} />
          </a>
          <a className={"social"} href="mailto:yuripfontoura@gmail.com">
            <ML size={"1.5vw"} />
          </a>
        </Fade>
      </aside>
    </Slide>
  );
};

export default Sidebar;
