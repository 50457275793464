import Div100vh from "react-div-100vh";
import React from "react";
import TopLayer from "../ui/LayerTop";
import BottonLayer from "../ui/LayerBotton";
import classes from "./Mainview2.module.css";
import "./Mainview6.css";
import { HiArrowLongRight as RArrow } from "react-icons/hi2";
import { CgQuote as Quote } from "react-icons/cg";

import { Fade, Slide } from "react-awesome-reveal";
import EndingLayer from "../ui/LayerEnding";

import Logo from "../../img/logo.png";
import Photo from "../../img/photo.png";

const Mainview5 = (props) => {
  return (
    <Div100vh className={classes.content2}>
      <TopLayer>
        <Slide className={classes.slideTitle} direction="left">
          <Fade delay={350}>
            <h1 className={classes.pageTitle}>a few words...</h1>
          </Fade>
        </Slide>
      </TopLayer>
      <BottonLayer>
        <div className={classes.leftPanel}>
          <img src={Logo} className={classes.logo} alt="logo" />
          <img src={Photo} className={classes.photo} alt="logo" />
          <blockquote>
            <Fade delay={300} cascade duration={800}>
              <Quote className={classes.quote} />
              <Slide direction="up" delay={200}>
                <h4>
                  talent wins games, but teamwork and intelligence win
                  championships.
                </h4>
              </Slide>
              <Slide direction="right" delay={500}>
                <p>- Michael Jordan</p>
              </Slide>
            </Fade>
          </blockquote>
        </div>
        <div className={classes.rightPanel}>
          <Fade delay={1100} cascade duration={800}>
            <Slide direction="right" delay={800}>
              <h1>ready to join me to the team?</h1>
            </Slide>
            <Slide direction="right" delay={1100}>
              <p>share the job with me, and let me meet the amazing team</p>
            </Slide>
            <a href="mailto:yuripfontoura@gmail.com"
              className="contactMe"
            >
              Contact Me
              <RArrow className="rArrow" />
            </a>
          </Fade>
        </div>
      </BottonLayer>
      <EndingLayer>
        <p className={classes.endingText}>© 2023 Yuri Fontoura</p>
      </EndingLayer>
    </Div100vh>
  );
};

export default Mainview5;
