import classes from "./Lines.module.css";
import bcast from "./Broadcast.module.css";

const Lines = (props) => {
  return (
    <div className={`${bcast.defaultWidth} ${bcast.defaultGrid} ${classes.fullLine}`}>
      <div id={classes.line2} className={classes.mid}></div>

      <div id={classes.line3} className={classes.small}></div>
      <div id={classes.line4} className={classes.small}></div>
      <div id={classes.line5} className={classes.large}></div>
      <div id={classes.line6} className={classes.large}></div>
      <div id={classes.line7} className={classes.small}></div>
      <div id={classes.line8} className={classes.small}></div>
      <div id={classes.line9} className={classes.large}></div>
    </div>
  );
};

export default Lines;
