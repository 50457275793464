import React, { Component } from "react";
import "./Animation.css";
import Logo from "../components/Logo";
import Typical from "react-typical";
import Div100vh from "react-div-100vh";

export default class Animation extends Component {
  render() {
    return (
      <Div100vh className="fullContent">
        <div className="animationMobile">
          <Logo.Dash />
          <Logo.Retangle />
          <Logo.Letters />
        </div>

        <div>
          <p style={{ fontSize: "1.5vh", fontStyle: "italic" }}>
            To view full content,
          </p>{" "}
          <p style={{ fontSize: "1.5vh", fontStyle: "italic" }}>
            please use a desktop device.
          </p>
          <Typical
            steps={[
              "I'm a Quant Dev",
              1000,
              "I'm a Designer",
              1000,
              "I'm a Trader",
              1000,
              "I'm a Data Analyst",
              1000,
              "I discuss...",
              1000,
              "Cryptocurrency",
              1000,
              "Trading Strategies",
              1000,
              "Algo Trading",
              1000,
              "Quant Concepts",
              1000,
            ]}
            loop={Infinity}
            wrapper="p"
          />
        </div>
      </Div100vh>
    );
  }
}
