import Div100vh from "react-div-100vh";
import React from "react";
import TopLayer from "../ui/LayerTop";
import BottonLayer from "../ui/LayerBotton";
import classes from "./Mainview2.module.css";

import { BsHeartFill as Heart } from "react-icons/bs";
import {
  FaBalanceScale as Balance,
  FaChalkboardTeacher as Teach,
  FaUserTie as Tie,
} from "react-icons/fa";
import { MdAltRoute as Route } from "react-icons/md";
import { VscDebugStart as Head } from "react-icons/vsc";
import { BsGraphUp as Graph } from "react-icons/bs";

import { Fade, Slide } from "react-awesome-reveal";

const Mainview5 = (props) => {
  return (
    <div className={classes.content2}>
      <TopLayer>
        <Slide className={classes.slideTitle} direction="right">
          <Fade delay={350}>
            <h1 className={classes.pageTitle}>passion</h1>
          </Fade>
        </Slide>
      </TopLayer>

      <BottonLayer>
        <Fade delay={0} className={classes.aiming}>
          <div className={classes.circle1}>
            <Fade delay={100}>
              <div className={classes.circle2}>
                <Fade delay={200}>
                  <div className={classes.circle3}>
                    <Fade delay={300}>
                      <Heart className={classes.heart} />
                      <span>
                        <Fade delay={400}>
                          <Slide direction="left" duration={1000}>
                            my values
                          </Slide>{" "}
                        </Fade>
                        <Fade delay={600}>
                          <h2>are</h2>
                        </Fade>
                      </span>
                    </Fade>
                  </div>
                </Fade>
              </div>
            </Fade>
          </div>
        </Fade>

        <div className={classes.values1}>
          <Fade cascade delay={900} damping={0.075}>

            <Slide direction="up" delay={900}>
              <h2>
                <Graph /> enthusiasm to learn and grow
              </h2>
            </Slide>
            <p>continuous learning</p>

            <Slide direction="up" delay={1000}>
              <h2>
                <Route /> flexibility
              </h2>
            </Slide>
            <p>adaptation for new challenges</p>

            <Slide direction="up" delay={1100}>
              <h2>
                <Balance /> work ethic
              </h2>
            </Slide>
            <p>knowing everyone differences and be respectful</p>
          </Fade>
        </div>

        <div className={classes.values2}>
          <Fade cascade delay={1400} damping={0.075}>
            <Slide direction="up" delay={1400}>
              <h2>
                <Head /> self-starter
              </h2>
            </Slide>
            <p>working alone had benefits</p>

            <Slide direction="up" delay={1500}>
              <h2>
                <Teach /> desire to teach
              </h2>
            </Slide>
            <p>is the best way for quality learning</p>

            <Slide direction="up" delay={1600}>
              <h2>
                <Tie /> professionalism
              </h2>
            </Slide>
            <p>good judgment and polite behavior</p>
          </Fade>
        </div>
      </BottonLayer>
    </div>
  );
};

export default Mainview5;
