import React from "react";
import classes from "./Layer.module.css";
import bcast from "../layout/desktop/Broadcast.module.css";

const EndingLayer = (props) => {
  return (
    <div className={`${bcast.defaultGrid} ${classes.ending}`}>
      {props.children}
    </div>
  );
};

export default EndingLayer;
