import React from "react";
import "./Logo.css"

import {ReactComponent as RetangleLogo} from '../../img/logo-retangle.svg';
import {ReactComponent as DashLogo}  from '../../img/logo-dash.svg';
import {ReactComponent as LettersLogo}  from '../../img/logo-letters.svg';

export const Retangle = props =>
    <RetangleLogo className="retangle-logo" alt="Logo"/>

export const Dash = props =>
    <DashLogo className="dash-logo" alt="Logo"/>

export const Letters = props =>
    <LettersLogo className="letters-logo" alt="Logo"/>

const Logo = {Retangle, Dash, Letters}

export default Logo