// import classes from "./Mainview.module.css";
import React, {Fragment, useEffect} from "react";

import Background from "../layout/desktop/Background";
import Mainview1 from "./Mainview1";
import Mainview2 from "./Mainview2";
import Mainview3 from "./Mainview3";
import Mainview4 from "./Mainview4";
import Mainview5 from "./Mainview5";
import Mainview6 from "./Mainview6";

import "./Mainview.module.css";

const Mainview=(props) => {
    const setHighlights=() => {
        const paginations=document.querySelectorAll("aside li");
        const sections=document.querySelectorAll("section");
        const titles=document.querySelectorAll("header li");

        let currentSection="";
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.top <= window.innerHeight / 3 && rect.bottom >= window.innerHeight / 3) {
                currentSection = section.getAttribute("id").split("-").pop();
            }
        });

        paginations.forEach((pagination) => {
            pagination.classList.remove("active");

            if(pagination.classList[1].includes(currentSection)) {
                pagination.classList.add("active");
            }
        });

        titles.forEach((title) => {
            title.classList.remove("active-title");
            if(title.classList[0].includes(currentSection)) {
                title.classList.add("active-title");
            }
        });
    };

    useEffect(() => {
        const container=document.getElementById("container");
        if(container) {

            container.removeEventListener("scroll", setHighlights);
            container.addEventListener("scroll", setHighlights, {
                passive: true,
            });
            return () => container.removeEventListener("scroll", setHighlights);
        }
    });

    useEffect(() => {
        const container=document.getElementById("container");
        if(container) {
            container.addEventListener("load", setHighlights);
            return () => container.addEventListener("load", setHighlights);
        }
    });

        return (
            <Fragment>
                <section id="section-home">
                    <Background/>
                    <Mainview1/>
                </section>

                <section id="section-services">
                    <Mainview2/>
                </section>

                <section id="section-projects">
                    <Mainview3/>
                </section>

                <section id="section-about">
                    <Mainview4/>
                </section>

                <section id="section-contact">
                    <Mainview5/>
                </section>

                <section id="section-ending">
                    <Mainview6/>
                </section>
            </Fragment>
        );
    };

export default Mainview;
