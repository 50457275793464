import "./Navbar.css";
// import { Link } from "react-router-dom";
import { HiArrowLongRight as RArrow } from "react-icons/hi2";
import { Fade } from "react-awesome-reveal";
import { scrollToSection } from "../../../functions/functions";

const Navbar = (props) => {
  return (
    <header>
      <Fade
        cascade
        delay={500}
        duration={400}
        className="navBar"
      >
        <ul className="navMenu">
          <li className="servicesItem navItem" >
            <span className= "services" onClick={() => scrollToSection("services")}>Services</span>
            {/* <Link to="/services">Services</Link> */}
          </li>

          <li className="projectsItem navItem">
            <span className="projects" onClick={() => scrollToSection("projects")}>Projects</span>
            {/* <Link to="/projects">Projects</Link> */}
          </li>

          <li className="aboutItem navItem">
            <span className="about" onClick={() => scrollToSection("about")}>Mission</span>
            {/* <Link to="/about">About</Link> */}
          </li>

          <li className="contactItem navItem">
            <span className="contact" onClick={() => scrollToSection("contact")}>Values</span>
            {/* <Link to="/contact">Contact</Link> */}
          </li>

          <li className="hireContainer">
            {/* <Link */}
            <span
              className="hireItem navItem"
              onClick={() => scrollToSection("ending")}
            >
              Contact
              <RArrow className="rArrow" />
            </span>
            {/* </Link> */}
          </li>
        </ul>
      </Fade>
    </header>
  );
};

export default Navbar;
