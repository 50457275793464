import classes from "./Content.module.css";
import bcast from "./Broadcast.module.css"
import React from "react";
import useScrollSnap from "./useScrollSnap";


const Content = (props) => {
    const containerRef = React.createRef();

    const [bind, unbind] = useScrollSnap(
        containerRef,
        { snapDestinationY: "100vh" }
    );

    return (
  // <div className={`${bcast.defaultWidth} ${classes.fullContent}`}>
      <div className={`${bcast.defaultWidth}`} id="container" ref={containerRef}>
        {props.children}
      </div>
        );
};

export default Content;
