import Div100vh from "react-div-100vh";
import React from "react";
import TopLayer from "../ui/LayerTop";
import BottonLayer from "../ui/LayerBotton";
import classes from "./Mainview2.module.css";

// import makingImg from "../../img/marketmaking.png";
// import arbImg from "../../img/arbitrage.png";
// import pairImg from "../../img/pairtrading.png";
// import copyImg from "../../img/copytrading.png";

import { BsPlus as Plus } from "react-icons/bs";
import { Fade, Slide } from "react-awesome-reveal";

const Mainview4 = (props) => {
  return (
    <div className={classes.content2}>
      <TopLayer>
        <Slide className={classes.slideTitle} direction="right">
          <Fade delay={350}>
            <h1 className={classes.pageTitle}>aim</h1>
          </Fade>
        </Slide>
      </TopLayer>

      <BottonLayer>
        <Fade delay={0} className={classes.aiming}>
          <div className={classes.circle1}>
            <Fade delay={50}>
              <div className={classes.circle2}>
                <Fade delay={100}>
                  <div className={classes.circle3}>
                    <Fade delay={600}>
                      <Plus className={classes.plus} />
                      <span>
                        <Fade delay={1000}>
                          <Slide direction="left" duration={1600}>
                            my mission
                          </Slide>{" "}
                        </Fade>
                        <Fade delay={1200}>
                          <h2>is</h2>
                        </Fade>
                      </span>
                    </Fade>
                  </div>
                </Fade>
              </div>
            </Fade>
          </div>
        </Fade>

        <Slide direction="up" className={classes.toDevelop}>
          <Fade delay={800}>
            <h1>to develop strategic solutions in finance industry</h1>

            <Fade cascade delay={700} duration={400}>
              <p>
                When I was a child I had the dream to plant a tree of money.
                Even that is not possible, inspired on that, I decided to learn
                ways to do a "money maker" system. That way, I studied financial
                market and discovered that money can make more money.
              </p>
              <p>
                Anyway, staying in front of desk, waiting opportunitties, could
                be very annoying most of time. Bytheway, with my programming
                background, I decided to create software routines to avoid
                annoying moments and scale strategies and even more by trying to create
                100% autonomous systems.
              </p>
              <p>
                In the middle of process, I discovered that a huge amount of
                capital market trades are not human operated. Not only that, but
                as long as time pass, it is getting more and more competitive
                and complex to work solely. Hence I decided to work in a team to
                colaborate, share everything I know and also learn from them.
              </p>
              <p>
                Thus since when I started my solo journey, in 2012, I've been
                investing all my efforts from programming to data science and
                finance, including time spent, studies and knowlegment to make
                my dream comes true. And that dream is directly correlated with
                my mission.
              </p>
            </Fade>
          </Fade>
        </Slide>
      </BottonLayer>
    </div>
  );
};

export default Mainview4;
