import Div100vh from "react-div-100vh";
import React from "react";
import TopLayer from "../ui/LayerTop";
import BottonLayer from "../ui/LayerBotton";
import classes from "./Mainview2.module.css";

import makingImg from "../../img/marketmaking.png";
import arbImg from "../../img/arbitrage.png";
import pairImg from "../../img/pairtrading.png";
import copyImg from "../../img/copytrading.png";

import { Fade, Slide } from "react-awesome-reveal";

const Mainview3 = (props) => {
  return (
    <div className={classes.content2}>
      <TopLayer>
        <Slide className={classes.slideTitle} direction="left">
          <Fade delay={350}>
            <h1 className={classes.pageTitle}>projects</h1>
          </Fade>
        </Slide>
      </TopLayer>

      <BottonLayer>
        <Slide delay={250} direction="up" className={classes.programming}>
          <Fade delay={450}>
            <div className={classes.programming}>
              <img src={makingImg} alt="market making" />
              <h4>market making</h4>
              <p>
                Searching and finding the best coins to quote both buy and sell
                price hoping to make profit on the bid-offer spread.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={300} direction="up" className={classes.modeling}>
          <Fade delay={500}>
            <div className={classes.arbitrage}>
              <img src={arbImg} alt="arbitrage" />
              <h4>arbitrage</h4>
              <p>
                Triangular or exchange arbitrage, exploiting an opportunity from
                a princing discreapancy among different currencies or diferrent
                exchanges.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={350} direction="up" className={classes.visualization}>
          <Fade delay={550}>
            <div className={classes.visualization}>
              <img src={pairImg} alt="pairtraidng" />
              <h4>pair trading</h4>
              <p>
                Entering two positions (one long and one short) that have
                deviated from each other expecting them to revert back to
                deviation mean.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={400} direction="up" className={classes.backtesting}>
          <Fade delay={600}>
            <div className={classes.backtesting}>
              <img src={copyImg} alt="backtesting" />
              <h4>copy trading</h4>
              <p>
                Trading management based upon each individual account wallet.
                Using telegram as frontend to send and receive new data from
                backend system.
              </p>
            </div>
          </Fade>
        </Slide>
      </BottonLayer>
    </div>
  );
};

export default Mainview3;
