import classes from "./Background.module.css"
import video from "../../../video/bg-video.mp4"
import { Fade } from "react-awesome-reveal";


const Background = props =>{
return(
    <Fade triggerOnce delay={3300} className={classes.bgvideo}>
    <video className={classes.bgvideo} src={video} autoPlay loop muted/>
    </Fade>
)
}

export default Background
