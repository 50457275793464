import React from "react";
import "./Logo.css"

import retangleLogo from '../img/retangle-logo.svg';
import dashLogo from '../img/dash-logo.svg';
import lettersLogo from '../img/letters-logo.svg';

export const Retangle = props =>
    <img src={retangleLogo} className="retangle-logo-mobile" alt="Logo"/>

export const Dash = props =>
    <img src={dashLogo} className="dash-logo-mobile" alt="Logo"/>

export const Letters = props =>
    <img src={lettersLogo} className="letters-logo-mobile" alt="Logo"/>

const Logo = {Retangle, Dash, Letters}

export default Logo