// import classes from "./Mainview2.module.css";
// import bcast from "../layout/desktop/Broadcast.module.css";
import Div100vh from "react-div-100vh";
import React from "react";
import TopLayer from "../ui/LayerTop";
import BottonLayer from "../ui/LayerBotton";
import classes from "./Mainview2.module.css";

import progImg from "../../img/programming.png";
import modelImg from "../../img/modeling.png";
import bckTstImg from "../../img/backtesting.png";
import visualImg from "../../img/visualization.png";

import { Fade, Slide } from "react-awesome-reveal";


const Mainview2 = (props) => {
  return (
    <div className={classes.content2}>
      <TopLayer>
        <Slide className={classes.slideTitle} direction="right">
          <Fade delay={350}>
            <h1 className={classes.pageTitle}>development</h1>
          </Fade>
        </Slide>
      </TopLayer>

      <BottonLayer>
        <Slide delay={250} direction="up" className={classes.programming}>
          <Fade delay={450}>
            <div className={classes.programming}>
              <img src={progImg} alt="programming" />
              <h4>programming</h4>
              <p>
                Including data science to bring best ways to develop
                strategies for capital market. Focus on python with the mainly
                use of jupyter notebook, pandas, numpy and exchange APIs.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={300} direction="up" className={classes.modeling}>
          <Fade delay={500}>
            <div className={classes.modeling}>
              <img src={modelImg} alt="modeling" />
              <h4>modeling</h4>
              <p>
                Creating a mathematical representation to make predictions and
                provide insights. Using of some stats models to get correlation
                and cointegration of prices as above example.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={350} direction="up" className={classes.visualization}>
          <Fade delay={550}>
            <div className={classes.visualization}>
              <img src={visualImg} alt="visualization" />
              <h4>visualization</h4>
              <p>
                Representation of data through graphics, such as charts, plots,
                infographics. Support from matplotlib to get advance of big
                data, reproducing any info graphically for human comprehension.
              </p>
            </div>
          </Fade>
        </Slide>

        <Slide delay={400} direction="up" className={classes.backtesting}>
          <Fade delay={600}>
            <div className={classes.backtesting}>
              <img src={bckTstImg} alt="backtesting" />
              <h4>backtesting</h4>
              <p>
                Used to testing predictives models on historical data. That way
                I can validate the profit probabilities of my strategies,
                gattering past results and projecting for future.
              </p>
            </div>
          </Fade>
        </Slide>
      </BottonLayer>
    </div>
  );
};

export default Mainview2;
