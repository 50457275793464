import classes from "./Start.module.css";
import { CgArrowDown as Arrow } from "react-icons/cg";
import { Fade, Slide } from "react-awesome-reveal";
import { scrollToSection } from "../../functions/functions";
const Start = (props) => {
  return (
    <Fade delay={3200} triggerOnce className={classes.startanimator}>
      <Slide direction="down" delay={2900} className={classes.startanimator} triggerOnce>
        <span className={classes.startcontainer} onClick={() => scrollToSection("services")}>
          <span className={classes.letter}>start</span>
          <Arrow className={classes.arrow} />
        </span>
      </Slide>
    </Fade>
  );
};

export default Start;
