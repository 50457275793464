
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import DesktopLayout from "./components/layout/Layout";
import Mainview from "./components/mainpage/Mainview";
// import Services from "./components/services/Services";
// import Projects from "./components/projects/Projects";
// import About from "./components/about/About";
// import Contact from "./components/contact/Contact";
// import Hire from "./components/hire/Hire";

const App = (props) => {
  return (
    <DesktopLayout>
      <Routes>
        <Route exact path="/" element={<Mainview />} />
        {/* <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/hire-me" element={<Hire />} /> */}
      </Routes>
    </DesktopLayout>
  );
};

export default App;
